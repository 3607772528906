import React from 'react'

import ProgressHero from './DashboardHero'
import Journey from './Journey/Journey'

const Progress = ({}) => {
	return (
		<>
			<ProgressHero />
			<Journey />
		</>
	)
}

export default Progress
