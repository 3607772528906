import React from 'react'

const exclamation = () => (
	<svg
		width="100%"
		height="100%"
		viewBox="0 0 50 50"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle cx="25" cy="25" r="25" fill="currentColor" />
		<rect x="21" y="8" width="8" height="21" rx="4" fill="white" />
		<rect x="21" y="34" width="8" height="8" rx="4" fill="white" />
	</svg>
)

export default exclamation
