/**
 * account
 */
export const AUTHENTICATE = 'AUTHENTICATE'
export const DEAUTHENTICATE = 'DEAUTHENTICATE'
export const SET_USER_DATA = 'SET_USER_DATA'

/**
 * cache
 */
export const SAVE_TO_CACHE = 'SAVE_TO_CACHE'

/**
 * studentData
 */
export const RESET_STUDENT_DATA = 'RESET_STUDENT_DATA'
export const SAVE_TO_PROGRESS = 'SAVE_TO_PROGRESS'
export const SET_STUDENT_DATA = 'SET_STUDENT_DATA'
export const SET_CURRENT_TRACK = 'SET_CURRENT_TRACK'
export const SET_CURRENT_TOPIC = 'SET_CURRENT_TOPIC'
export const SET_SUGGESTED_ACTIVITY = 'SET_SUGGESTED_ACTIVITY'

export const INCREMENT_GEMS_BY = 'INCREMENT_GEMS_BY'

/**
 * teacherData
 */
/* initialization processes */
export const SET_SUBMISSIONS = 'SET_SUBMISSIONS'

/* runtime processes */
export const UPDATE_FEEDBACKS = 'UPDATE_FEEDBACKS'
export const SET_CURRENT_CLASSROOM_BY_INDEX = 'SET_CURRENT_CLASSROOM_BY_INDEX'
export const SET_CURRENT_SUBMISSION_BY_INDEX = 'SET_CURRENT_SUBMISSION_BY_INDEX'

/**
 * learnData
 */
export const RESET_LEARN_DATA = 'RESET_LEARN_DATA'
export const SET_SELECTED_ACTIVITY = 'SET_SELECTED_ACTIVITY'
export const SET_INDICATORS = 'SET_INDICATORS'

export const SET_CURRENT_CARD_BY_INDEX = 'SET_CURRENT_CARD_BY_INDEX'
export const INCREMENT_CURRENT_CARD_INDEX = 'INCREMENT_CURRENT_CARD_INDEX'
export const SET_LAST_CARD_UNLOCKED_INDEX_BY_ID =
	'SET_LAST_CARD_UNLOCKED_INDEX_BY_ID'
export const INCREMENT_LAST_CARD_UNLOCKED_INDEX =
	'INCREMENT_LAST_CARD_UNLOCKED_INDEX'

export const BROADCAST_BUTTON_STATE = 'BROADCAST_BUTTON_STATE'
export const SCHEDULE_BUTTON_STATE = 'SCHEDULE_BUTTON_STATE'
export const RESET_BUTTON_STATE_SCHEDULE = 'RESET_BUTTON_STATE_SCHEDULE'

/**
 * theme
 */
export const SET_THEME = 'SET_THEME'
